import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ImageListItem from "@mui/material/ImageListItem";
import MuralArts from "../../assets/mural-arts.jpg";
import DesignS from "../../assets/designS.jpeg";
// import Button from "@mui/material/Button";

const News = () => {
  return (
    <div style={{ marginTop: "100px" }}>
      <Typography variant="h4" component="h5" sx={{ marginBottom: "20px" }}>
        NEWS & PUBLICATIONS
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <ImageListItem>
            <img
              style={{ borderRadius: "5%" }}
              src={MuralArts}
              alt="frp advantages"
              loading="lazy"
            />
          </ImageListItem>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="h6"
            component="h6"
            sx={{ fontSize: "17.5px", marginLeft: "30px" }}
          >
            Mindlabz Technologies is established by the entrepreneur having over
            a decade of field experience. Mindlabz primarily into develop the
            computer designs related to steel engineering, interiordecorative
            designs, custom mural and graffiti designs. And also facilitating
            the production of the interior decorative items for the clients by
            the manufacturers in india and abroad.
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <ImageListItem>
            <img
              style={{ borderRadius: "5%" }}
              src={DesignS}
              alt="frp advantages"
              loading="lazy"
            />
          </ImageListItem>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="h6"
            component="h6"
            sx={{ fontSize: "17.5px", marginLeft: "30px" }}
          >
            For last 3 years the organisation is major revenue source is through
            the creating the computer based 3D designs for infrastructure
            industry, interior decorative items and the outsourcing job work to
            manufacturing and moulding units • IT Support outsourcing. Turnkey
            hardware leasing and technical support. Voice process campaigns –
            Assignment based. Search Engine Optimization and Digital Marketing.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default News;
