import Layout from "./components/Layout";
import Navbar from "./components/Navbar";
import Carousel from "./components/Carousel";
import Advantages from "./components/Advantages";
import News from "./components/News";
import OurClients from "./components/OurClients";
import Footer from "./components/Footer";

function App() {
  return (
    <Layout>
      <Navbar />
      <Carousel />
      <div style={{ maxWidth: "86%", margin: "auto" }}>
        <Advantages />
        <News />
        <OurClients />
      </div>
      <Footer />
    </Layout>
  );
}

export default App;
