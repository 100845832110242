import React from "react";
import Typography from "@mui/material/Typography";
import ImageListItem from "@mui/material/ImageListItem";
import Phenix from "../../assets/clients/Phenix.webp";
import Arwan from "../../assets/clients/arwan.jpeg";
import Drdo from "../../assets/clients/drdo.jpeg";
import CraftedSpaces from "../../assets/clients/crafted-spaces.jpeg";
import Certified from "../../assets/clients/certified.jpeg";

const itemData = [
  {
    img: Phenix,
    title: "Breakfast",
  },
  {
    img: Arwan,
    title: "Burger",
  },
  {
    img: Drdo,
    title: "Camera",
  },
  {
    img: CraftedSpaces,
    title: "Coffee",
  },
  {
    img: Certified,
    title: "Hats",
  },
];

const titleStyles = {
  display: "flex",
  justifyContent: "center",
  marginTop: "120px",
};

const OurClients = () => {
  return (
    <div>
      <div style={titleStyles}>
        <Typography variant="h4" component="h5">
          Our Clients
        </Typography>
      </div>
      <div style={{ marginTop: "35px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {itemData.map((item) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ImageListItem
                key={item.title}
                style={{
                  width: "160px",
                  height: "120px",
                  marginBottom: "22px",
                }}
              >
                <img
                  style={{ borderRadius: "80%" }}
                  src={`${item.img}`}
                  alt="frp advantages"
                  loading="lazy"
                />
              </ImageListItem>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurClients;
