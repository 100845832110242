import React from "react";
// import ImageListItem from "@mui/material/ImageListItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Logo from "../../assets/logo2.jpeg";
import Box from "@mui/material/Box";

const copyStyles = {
  display: "flex",
  justifyContent: "center",
  marginTop: "30px",
};

const Footer = () => {
  return (
    <div
      style={{
        marginTop: "100px",
        backgroundColor: "#222",
        padding: "55px 50px 20px 50px",
      }}
    >
      <Grid container spacing={2} sx={{ marginBottom: "50px" }}>
        <Grid item xs={12} md={4}>
          <Box
            component="img"
            sx={{
              height: "80px",
              backgroundColor: "royalblue",
              borderRadius: "4px",
              width: "180px",
              textAlign: "center",
            }}
            src={Logo}
            alt="MindLabs Logo"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <div style={{ color: "white" }}>
            <Typography variant="h4" component="h5" sx={{ color: "royalblue" }}>
              MindLabz
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontSize: "15px", letterSpacing: "0.2px" }}
            >
              Mindlabz primarily into develop the computer designs related to
              steel engineering, interiordecorative designs, custom mural and
              graffiti designs.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div style={{ color: "white" }}>
            <Typography variant="h4" component="h5" sx={{ color: "royalblue" }}>
              Contact
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontSize: "15px", marginBottom: "5px" }}
            >
              Suite 302, MCR Complex, Ayyappa Society, Madhapur, Hyderabad,
              Telangana 500081
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontSize: "15px", marginBottom: "5px" }}
            >
              Info@mindlabz.co.in
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontSize: "15px" }}>
              +919700807008
            </Typography>
          </div>
        </Grid>
      </Grid>
      <div style={copyStyles}>
        <Typography
          variant="h6"
          component="h6"
          sx={{ fontSize: "12px", color: "royalBlue" }}
        >
          Made with <span className="text-red-600">♥</span> by California,
          Copyright @ {new Date().getFullYear()}
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
